import { toast } from 'react-toastify'

import { postRepeat, postRequestVideoArt } from 'api/neuro-technologies'
import { RequestGeneration } from 'types/FormDataObject'
import Handlers from 'types/Handlers'
import { BaseResponse, LimitError } from 'types/Response'
import Strategy from './Strategy'

export default class VideoStrategy extends Strategy {
  private readonly requestId?: string

  private readonly text: string

  private readonly videoText?: string

  private responseSeconds?: number

  private handler: `${Handlers}` = Handlers.JOHN_QUINCY_ADAMS

  constructor(request: string, videoText?: string, requestId?: string) {
    super()
    this.text = request
    this.videoText = videoText
    this.requestId = requestId
  }

  public getSeconds() {
    return this.responseSeconds || 30
  }

  public async start(): Promise<void> {
    const { id, assets, meta } = await this.makeRequest()
    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public stop(): void {
    this.collector?.stop()
  }

  public async repeat(): Promise<void> {
    const { id, assets, meta } = await this.makeRequestRepeat()

    this.responseSeconds = meta.response_seconds
    this.collectAssets(id, assets.length).then()
  }

  public async makeRequest(): Promise<BaseResponse> {
    try {
      console.log(this.videoText)
      const requestData: RequestGeneration = {
        text: this.text,
        videoText: this.videoText,
        isManyAssets: true,
      }

      return await postRequestVideoArt(requestData)
    } catch (error: LimitError | unknown) {
      this.collector?.stop()

      const errorData = error as LimitError
      if (errorData.key === 'LIMIT_GENERATION') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorData
      }

      toast.error('Something went wrong, try again!')
      throw error
    }
  }

  public async makeRequestRepeat(): Promise<BaseResponse> {
    try {
      return await postRepeat(this.requestId!, this.text)
    } catch (error) {
      toast.error('Something went wrong, try again!')
      this.collector?.stop()

      throw error
    }
  }
}
