import React from 'react'

import Container from 'components/common/Container'
import GenerationFixedButton from 'components/GenerationFixedButton'
import GenerationInput from 'components/GenerationInput'
import GenerationStyles from 'components/GenerationStyles'
import Suggestions from 'components/Suggestions'
import { Style, Suggestion } from 'types/Generation'
import RequestGenerationContainer from '../../../RequestGenerationContainer'

import c from './IdleStage.module.scss'

interface IdleStageProps {
  text: string
  videoText: string
  suggestions?: Suggestion[]
  styles?: Style[]
  chosenStyle: string | undefined
  onTextChange: (newText: string) => void
  onVideoTextChange: (newText: string) => void
  onProcessingStart: () => void
  onExampleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onStyleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function IdleStage({
  text,
  videoText,
  suggestions = [],
  styles = [],
  chosenStyle,
  onTextChange,
  onVideoTextChange,
  onProcessingStart,
  onExampleClick,
  onStyleClick,
}: IdleStageProps) {
  return (
    <Container flex>
      <div className={c.content}>
        <RequestGenerationContainer
          promptTipsVisible
          generationTextAreaVisible
          suggestions={suggestions}
          text={text}
          videoText={videoText}
          onTextChange={onTextChange}
          onVideoTextChange={onVideoTextChange}
          onProcessingStart={onProcessingStart}
          onExampleClick={onExampleClick}
          generationVideoTextAreaVisible
        />
        <GenerationStyles
          chosenStyle={chosenStyle}
          styles={styles}
          onStyleClick={onStyleClick}
        />
      </div>
    </Container>
  )
}

export default IdleStage
