import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AutoFixIcon } from '@material-design-icons/svg/round/auto_fix_high.svg'

import TextArea from '../common/TextArea'

import c from './GenerationTextArea.module.scss'

interface GenerationTextAreaProps {
  text: string
  onTextChange: (newText: string) => void
  onProcessingStart: () => void
  page?: string
  inputRef?: React.RefObject<HTMLTextAreaElement>
  placeholder?: string
}

function GenerationTextArea({
  text,
  onTextChange,
  onProcessingStart,
  page = '',
  inputRef = undefined,
  placeholder = undefined,
}: GenerationTextAreaProps) {
  const { t } = useTranslation()

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onTextChange(e.target.value)
    },
    [onTextChange],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== 'Enter') return
      onProcessingStart()
    },
    [onProcessingStart],
  )

  return (
    <>
      {placeholder ? null : (
        <p className={c.heading}>{t('stages.generation.idle.inputLabel')}</p>
      )}
      <TextArea
        className={c.input}
        placeholder={
          placeholder ||
          (page === 'mixer'
            ? t('stages.mixer.idle.inputPlaceholderMixer')
            : t('stages.mixer.idle.areaPlaceholderMixer'))
        }
        startIcon={<AutoFixIcon style={{ fill: 'currentColor' }} />}
        value={text}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        textareaRef={inputRef}
      />
    </>
  )
}

export default GenerationTextArea
